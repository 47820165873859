import sidebarMixin from './sidebar';
import sidebarService from '../services/sidebar';

export default {
  mixins: [sidebarMixin],
  methods: {
    openSidebar () {
      sidebarService.open();
    },
    closeSidebar () {
      sidebarService.close();
    },
    toggleSidebar () {
      sidebarService.toggle();
    },
  },
};
